let BASE_API = "https://utopie.ma/api/v1"
let data = {
    "api": {
        "NEWLETTER_API": `${BASE_API}/newletter/submit`,
        "FORM_API": `${BASE_API}/form/submit`
    },
    "contact": {
        "questions": [
            {
                "question": `
                    Quel est votre nom? *
                `,
                "type": "question",
                "name": "quel_est_votre_nom",
                "validate": ["require"],
                "options": []
            },{
                "question": `
                    Quelle est votre meilleure adresse e-mail, _____? *
                    <small>Nous allons l'utiliser pour vous répondre. Pas de spam ou de newsletters inattendues.</small>
                `,
                "type": "question",
                "name": "quelle_est_votre_meilleur",
                "validate": ["require", "email"],
                "options": []
            },{
                "question": `
                    Quelle est la raison pour laquelle vous nous contactez?
                    <small>Pensez à cela comme à un champ d'objet dans un courrier électronique. Mais déjà rempli pour vous.</small>
                `,
                "type": "options",
                "name": "quelle_est_la_raison_pour",
                "validate": [],
                "options": [
                    "J'aimerais travailler avec vous",
                    "J'ai des feedback",
                    "Je cherche des conseils",
                    "Je voudrais juste discuter"
                ]
            },{
                "question": `
                    Quel est votre message?
                `,
                "type": "question-text",
                "name": "quel_est_votre_message",
                "validate": ["require"],
                "options": []
            },
        ]
    }
}

export default data