import {reset} from 'redux-form';
import database from "../../../public/js/database"
import {toastr} from 'react-redux-toastr'

const axios = require('axios')

export const postNewsLetterSuccess = state => ({
  type: 'NEWS_LETTER_SUCCESS',
  state
})

export const postNewsLetterError = state => ({
  type: 'NEWS_LETTER_ERROR',
  state
})

export const postNewsLetterCompleted = state => ({
  type: 'NEWS_LETTER_COMPLETED',
  state
})

export const postContactSuccess = state => ({
  type: 'CONTACT_SUCCESS',
  state
})

export const postContactError = state => ({
  type: 'CONTACT_ERROR',
  state
})

export const postContactCompleted = state => ({
  type: 'CONTACT_COMPLETED',
  state
})

export const submitNewsLetter = ({additionalData, callBack}) => {
  return (dispatch, getState) => {
    dispatch(postNewsLetterCompleted(false))
    dispatch(postNewsLetterSuccess(false))
    dispatch(postNewsLetterError(false))
    const { form } = getState()
    const data = { ...additionalData, ...form["news-letter"].values}
    axios.post(database.api.NEWLETTER_API, data).then(function (response) {
      toastr.success('Merci', "Merci de nous avoir contacté")
      dispatch(postNewsLetterSuccess(true))
      dispatch(postNewsLetterCompleted(true))
      dispatch(reset('news-letter'));
    }).catch(function (error) {
      let dataString = "There is some kind of problem with your request"
      try {
        dataString = JSON.stringify(error.response.data)
      } catch (e) {}
      toastr.error('Erreur', dataString)
      dispatch(postNewsLetterError(true))
      dispatch(postNewsLetterCompleted(true))
    })
  }
}

export const submitContact = ({additionalData, callBack}) => {
  return (dispatch, getState) => {
    dispatch(postContactCompleted(false))
    dispatch(postContactSuccess(false))
    dispatch(postContactError(false))
    const { form } = getState()
    const data = { ...additionalData, ...form["ContactForm"].values}
    axios.post(database.api.FORM_API, data).then(function (response) {
      toastr.success('Merci', "Merci de nous avoir contacté")
      dispatch(postContactSuccess(true))
      dispatch(postContactCompleted(true))
      dispatch(reset('ContactForm'));
      callBack()
    }).catch(function (error) {
      let dataString = "There is some kind of problem with your request"
      try {
        dataString = JSON.stringify(error.response.data)
      } catch (e) {}
      toastr.error('Erreur', dataString)
      dispatch(postContactError(true))
      dispatch(postContactCompleted(true))
      callBack(true)
    })
  }
}

